import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import AuthenticationProvider from '@bit/medicalwebexperts.mwe-ui.auth-context';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import SnackbarProvider from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';
import client from './services/client';
import theme from './themes/preset';
import PermissionsProvider from './components/Permissions/Permissions';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import 'react-datepicker/dist/react-datepicker.css';

moment.defaultFormat = 'M/D/YYYY hh:mm a';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Normalize />
      <AuthenticationProvider
        client={client}
        storageKey="feathers-jwt"
        idle={10800000}
      >
        <PermissionsProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </PermissionsProvider>
      </AuthenticationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
