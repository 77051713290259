import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module'

import PixelAnalitycs from '../../contexts/PixelAnalitycs';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoggedOutLayout from '../Layout/LoggedOutLayout';
import LoggedInLayout from '../Layout/LoggedInLayout';
import LoggedOutWideLayout from '../Layout/LoggedOutWideLayout';
import DataProvider from '../../contexts/DataProvider';
import PublicRoute from '../Routing/PublicRoute';
import PrivateRoute from '../Routing/PrivateRoute';
import ScrollToTop from './ScrollToTop';
import Loader from './Loader';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

TagManager.initialize(tagManagerArgs)

const TermsAndConditionsPage = lazy(() =>
  import('../../pages/TermsAndConditions/TermsAndConditions'),
);

const LoginPage = lazy(() => import('../../pages/Account/Login/Login'));
const RegisterPage = lazy(() =>
  import('../../pages/Account/Register/Register'),
);
const RegisterWithSessionPage = lazy(() =>
  import('../../pages/Account/Register/RegisterWithSession'),
);
const RegisterWithSessionParentPage = lazy(() =>
  import('../../pages/Account/Register/RegisterWithSessionParent'),
);
const RegisterWithSessionChildPage = lazy(() =>
  import('../../pages/Account/Register/RegisterWithSessionChild'),
);

const RegisterWelcomePage = lazy(() =>
  import('../../pages/Account/Register/Welcome'),
);
const ForgotPasswordPage = lazy(() =>
  import('../../pages/Account/ForgotPassword/ForgotPassword'),
);
const ResetPasswordPage = lazy(() =>
  import('../../pages/Account/ResetPassword/ResetPassword'),
);
const ProfilePage = lazy(() => import('../../pages/Profile/Profile'));

const UserListPatientPage = lazy(() =>
  import('../../pages/Patient/ListPatients'),
);
const UserListPage = lazy(() => import('../../pages/User/List'));
const UserCreatePage = lazy(() => import('../../pages/User/Create'));
const UserEditPage = lazy(() => import('../../pages/User/Edit'));

const OrganizationListPage = lazy(() =>
  import('../../pages/Organization/List'),
);
const OrganizationCreatePage = lazy(() =>
  import('../../pages/Organization/Create'),
);
const OrganizationEditPage = lazy(() =>
  import('../../pages/Organization/Edit'),
);

const AppointmentListPage = lazy(() =>
  import('../../pages/Appointment/List/List'),
);
const AppointmentCreatePage = lazy(() =>
  import('../../pages/Appointment/Create/Create'),
);
const AppointmentPaymentPage = lazy(() =>
  import('../../pages/Appointment/Payment/Payment'),
);

const ThankYouPage = lazy(() =>
  import('../../pages/Appointment/Payment/ThankYou'),
);

const IntakePCRCovid19Page = lazy(() =>
  import('../../pages/Intake/PCRCovid19/PCRCovid19'),
);
const IntakePCRCovid19InsurancePage = lazy(() =>
  import('../../pages/Intake/PCRCovid19Insurance/PCRCovid19Insurance'),
);
const IntakeAntibodyPage = lazy(() =>
  import('../../pages/Intake/Antibody/Antibody'),
);

const IntakeAntibodyInsurancePage = lazy(() =>
  import('../../pages/Intake/AntibodyInsurance/AntibodyInsurance'),
);

const MyTestListPage = lazy(() => import('../../pages/MyTest/List'));

const LabOrderListPage = lazy(() => import('../../pages/LabOrder/List/List'));
const LabOrderViewPage = lazy(() => import('../../pages/LabOrder/View/View'));

const EVisitListPage = lazy(() => import('../../pages/EVisit/List/List'));

const ActivityLogListPage = lazy(() => import('../../pages/ActivityLog/List'));

const NotFoundPage = lazy(() => import('../../pages/404'));

const TrakingPage = lazy(() => import('../../pages/AdminTools/Traking'));
const LabOrderUnreported = lazy(() =>
  import('../../pages/AdminTools/LabOrderUnreported'),
);
const Queue = lazy(() => import('../../pages/AdminTools/Queue'));
const AdminDashboard = lazy(() =>
  import('../../pages/AdminTools/AdminDashboard'),
);
const TestResult = lazy(() => import('../../pages/TestResult/TestResult'));

const App = () => (
  <PixelAnalitycs>
    <Router>
      <Helmet titleTemplate="%s - Valley Medical Laboratory" />
      <ScrollToTop />
      <ErrorBoundary>
        <Switch>
          <PublicRoute path="/account">
            <LoggedOutLayout>
              <Loader>
                <Switch>
                  <Route path="/account/login" exact>
                    <LoginPage />
                  </Route>
                  <Route path="/account/forgot-password" exact>
                    <ForgotPasswordPage />
                  </Route>
                  <Route path="/account/reset-password/:uid/:token" exact>
                    <ResetPasswordPage />
                  </Route>
                  <Redirect to="/account/login" />
                </Switch>
              </Loader>
            </LoggedOutLayout>
          </PublicRoute>

          <PublicRoute path="/view-results">
            <Loader>
              <Switch>
                <Route path="/view-results/:token" exact>
                  <TestResult />
                </Route>
                <Redirect to="/account/login" />
              </Switch>
            </Loader>
          </PublicRoute>

          <PublicRoute path="/register">
            <LoggedOutWideLayout>
              <Loader>
                <Switch>
                  <Route path="/register/:organization?" exact>
                    <RegisterPage />
                  </Route>
                  <Route path="/register/welcome" exact>
                    <RegisterWelcomePage />
                  </Route>
                </Switch>
              </Loader>
            </LoggedOutWideLayout>
          </PublicRoute>

          <PrivateRoute path="/">
            <LoggedInLayout>
              <Loader>
                <DataProvider>
                  <Switch>
                    <Route path="/appointments">
                      <Switch>
                        <Route path="/appointments" exact>
                          <AppointmentListPage />
                        </Route>
                        <Route path="/appointments/schedule/payment" exact>
                          <AppointmentPaymentPage />
                        </Route>
                        <Route path="/appointments/schedule/:id?" exact>
                          <AppointmentCreatePage />
                        </Route>
                        <Route path="/appointments/thankyou" exact>
                          <ThankYouPage />
                        </Route>

                        <Redirect to="/my-tests" />
                      </Switch>
                    </Route>

                    <Route path="/new-patient">
                      <Switch>
                        <Route path="/new-patient" exact>
                          <RegisterWithSessionPage />
                        </Route>
                        <Route path="/new-patient/parent" exact>
                          <RegisterWithSessionParentPage />
                        </Route>
                        <Route path="/new-patient/child" exact>
                          <RegisterWithSessionChildPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/intake">
                      <Switch>
                        <Route path="/intake/prccovid19">
                          <IntakePCRCovid19Page />
                        </Route>
                        <Route path="/intake/prccovid19insurance">
                          <IntakePCRCovid19InsurancePage />
                        </Route>
                        <Route path="/intake/antibody">
                          <IntakeAntibodyPage />
                        </Route>
                        <Route path="/intake/antibodyInsurance">
                          <IntakeAntibodyInsurancePage />
                        </Route>
                        <Redirect to="/my-tests" />
                      </Switch>
                    </Route>

                    <Route path="/my-tests" exact>
                      <MyTestListPage />
                    </Route>

                    <Route path="/profile">
                      <ProfilePage />
                    </Route>

                    <Route path="/lab-orders">
                      <Switch>
                        <Route path="/lab-orders" exact>
                          <LabOrderListPage />
                        </Route>
                        <Route path="/lab-orders/:id" exact>
                          <LabOrderViewPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/admin">
                      <Switch>
                        <Route path="/admin" exact>
                          <AdminDashboard />
                        </Route>
                        <Route path="/admin/lab-orders/unreported" exact>
                          <LabOrderUnreported />
                        </Route>
                        <Route path="/admin/queue" exact>
                          <Queue />
                        </Route>
                        <Route path="/admin/lab-orders/tracking/:id" exact>
                          <TrakingPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/e-visits">
                      <Switch>
                        <Route path="/e-visits" exact>
                          <EVisitListPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/organizations">
                      <Switch>
                        <Route path="/organizations" exact>
                          <OrganizationListPage />
                        </Route>
                        <Route path="/organizations/create" exact>
                          <OrganizationCreatePage />
                        </Route>
                        <Route path="/organizations/:id" exact>
                          <OrganizationEditPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/users">
                      <Switch>
                        <Route path="/users" exact>
                          <UserListPage />
                        </Route>
                        <Route path="/users/create" exact>
                          <UserCreatePage />
                        </Route>
                        <Route path="/users/:id" exact>
                          <UserEditPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/patients">
                      <Switch>
                        <Route path="/patients" exact>
                          <UserListPatientPage />
                        </Route>
                      </Switch>
                    </Route>

                    <Route path="/activity-logs" exact>
                      <ActivityLogListPage />
                    </Route>

                    <Route path="/terms-and-conditions" exact>
                      <TermsAndConditionsPage />
                    </Route>

                    <Route path="*">
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </DataProvider>
              </Loader>
            </LoggedInLayout>
          </PrivateRoute>
        </Switch>
      </ErrorBoundary>
    </Router>
  </PixelAnalitycs>
);

export default App;
