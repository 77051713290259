import feathers from '@feathersjs/client';
import axios from 'axios';

const client = feathers();
const rest = feathers.rest(process.env.REACT_APP_API_BASE_URL);

client.configure(rest.axios(axios));
client.configure(feathers.authentication());

export default client;
