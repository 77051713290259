import React, { useState, useEffect, useContext, createContext } from 'react';
import { node } from 'prop-types';
import { ORDER_TYPES } from '../constants/lab';
import client from '../services/client';

export const DataContext = createContext();

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataContext');
  }
  return context;
};

const propTypes = {
  children: node.isRequired,
};

const DataProvider = ({ children }) => {
  const [context, setContext] = useState({});
  const [mounted, setMount] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const labTypesDb = await client.service('lab-types').find();

        const labTypesListDb = labTypesDb.data.map((row) => ({
          label: row.label,
          value: row.id,
        }));

        const typesDb = labTypesDb.data.reduce(
          (acc, row) => ({ ...acc, [row.testCode]: row.id }),
          {},
        );

        const typesCodeDb = labTypesDb.data.map((row) => ({
          label: row.label,
          value: row.testCode,
        }));

        setContext({
          LAB_TYPE_FULL: labTypesDb.data,
          LAB_TYPES_LIST: labTypesListDb,
          LAB_TYPES: typesDb,
          LAB_TYPES_CODE_LIST: typesCodeDb,
          TEST_CODES: {
            covidPcr: 193,
            covidPcrFlu: 196,
            covidAntibody: 176,
            covidAntibodyIgGIgM: 187,
            covidAntibodyOLD: 163, // retro compatibility
            covidPcrOLD: 161, // retro compatibility
          },
          ORDER_TYPES,
        });
        setMount(true);
      } catch (error) {
        throw Error(error);
      }
    })();
  }, []);

  return (
    <DataContext.Provider value={context}>
      {mounted ? children : null}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = propTypes;

export default DataProvider;
