import React, { Component } from 'react';
import { node } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import errorGraphic from '../../assets/images/error-graphic.png';
import errorGraphic2x from '../../assets/images/error-graphic@2x.png';
import logo from '../../assets/images/logo.png';
import logo2x from '../../assets/images/logo@2x.png';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: ['100vh', '90vh'],
    p: 4,
    width: '100%',
  }),
);

const Logo = styled.div(
  css({
    mb: 8,
  }),
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? (
      <Main>
        <Box textAlign="center">
          <Logo>
            <img
              src={logo}
              srcSet={`${logo} 1x, ${logo2x} 2x`}
              alt="Valley Medical Laboratory"
            />
          </Logo>
          <Logo>
            <img
              src={errorGraphic}
              srcSet={`${errorGraphic} 1x, ${errorGraphic2x} 2x`}
              alt="Internal Error"
            />
          </Logo>
          <Typography variant="h1" paragraph>
            Sorry something went wrong...
          </Typography>
          <Button variantColor="primary" as={Link} to="/">
            Back to Home
          </Button>
        </Box>
      </Main>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;
