import React from 'react';
import { bool, node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import logo from '../../assets/images/logo.png';
import logo2x from '../../assets/images/logo@2x.png';
import leftDoc from '../../assets/images/left-doc.png';
import rightDoc from '../../assets/images/right-doc.png';

const propTypes = {
  children: node.isRequired,
  register: bool,
};

const defaultProps = {
  register: true,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90vh',
    px: 4,
    width: '100%',
  }),
);

const Wrapper = styled(Card)(
  css({
    mx: -6,
    position: 'relative',
    width: '350px',
    zIndex: 'docked',
  }),
);

const Logo = styled.img(
  css({
    display: 'block',
    mb: 2,
    mx: 'auto',
  }),
);

const DoctorImage = styled.img(
  css({
    display: ['none', null, 'block'],
  }),
);

const LoggedOutLayout = ({ children, register }) => (
  <Main>
    <DoctorImage src={leftDoc} />
    <Box>
      <Wrapper>
        <Card.Body textAlign="center">
          <Logo
            src={logo}
            srcSet={`${logo} 1x, ${logo2x} 2x`}
            alt="Valley Medical Laboratory"
          />
          {children}
        </Card.Body>
      </Wrapper>

      {register && (
        <Typography mt={4} textAlign="center">
          Don&apos;t have an account?{' '}
          <Link as={RouterLink} to="/register" color="secondary.main">
            Register here
          </Link>
        </Typography>
      )}
    </Box>
    <DoctorImage src={rightDoc} />
  </Main>
);

LoggedOutLayout.propTypes = propTypes;
LoggedOutLayout.defaultProps = defaultProps;

export default LoggedOutLayout;
