import React from 'react';
import { shape } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { MdExitToApp, MdInfo } from 'react-icons/md';
import {
  useAuth,
  useAuthDispatch,
} from '@bit/medicalwebexperts.mwe-ui.auth-context';
import BaseSidebar from '@bit/medicalwebexperts.mwe-ui.sidebar';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import logo from '../../assets/images/logo.png';
import logo2x from '../../assets/images/logo@2x.png';
import { Can } from '../Permissions/Permissions';
import sidebarItems from './sidebarItems';

const propTypes = {
  state: shape({}).isRequired,
};

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const Logo = styled.div(
  css({
    img: {
      maxWidth: '100%',
    },
    my: 4,
    px: 4,
  }),
);

const Profile = styled.div(
  css({
    alignItems: 'center',
    bg: 'whiteAlpha.800',
    display: ['none', null, null, 'flex'],
    mx: 4,
    mt: 4,
    mb: 10,
    p: 4,
    borderRadius: 'md',
    lineHeight: 0,
  }),
);

const ListItem = styled(List.Item)(
  css({
    '&:hover': {
      bg: 'whiteAlpha.600',
    },
    '&.ListItem--active': {
      bg: 'whiteAlpha.600',
      borderRightColor: 'primary.main',
    },
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'text',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'medium',
    p: 4,
  }),
);

const sidebarClasses = {
  root: {
    border: 'none',
    bg: 'gray.100',
  },
};

const Sidebar = (props) => {
  const { user } = useAuth();
  const { logout } = useAuthDispatch();
  const { state } = props;

  const items = sidebarItems.map(
    ({ label, to, action, subject, Icon, exact = true }) => (
      <Can I={action} a={subject} key={to}>
        {() => (
          <ListItem
            as={NavLink}
            to={to}
            onClick={state.hide}
            activeClassName="ListItem--active"
            exact={exact}
          >
            <List.Item.Icon color="primary.main">
              <Icon size={22} />
            </List.Item.Icon>
            {label}
          </ListItem>
        )}
      </Can>
    ),
  );

  return (
    <BaseSidebar {...props} classes={sidebarClasses}>
      <Wrapper>
        <Logo>
          <img
            src={logo}
            srcSet={`${logo} 1x, ${logo2x} 2x`}
            alt="Valley Medical Laboratory"
          />
        </Logo>
        <Profile>
          <Avatar name={user.name} size="xs" />
          <Box pl={4}>
            <Typography fontWeight="bold">{user.name}</Typography>
            <Link
              variant="caption"
              as={RouterLink}
              to="/profile"
              onClick={state.hide}
              color="secondary.main"
            >
              Profile
            </Link>
          </Box>
        </Profile>
        <List as="nav" flexGrow="1">
          {items}
        </List>
        <ListItem as={NavLink} to="/terms-and-conditions" bg="gray.200">
          <List.Item.Icon color="gray.400">
            <MdInfo size={22} />
          </List.Item.Icon>
          Terms and Conditions
        </ListItem>
        <ListItem as="a" onClick={logout} bg="gray.200">
          <List.Item.Icon color="danger">
            <MdExitToApp size={22} />
          </List.Item.Icon>
          Logout
        </ListItem>
      </Wrapper>
    </BaseSidebar>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
