export const ORDER_STATUS = {
  NEW: 'NEW',
  SCHEDULED: 'SCHEDULED',
  COLLECTED: 'COLLECTED',
  RECEIVED_BY_LAB: 'RECEIVED_BY_LAB',
  REPORTED: 'REPORTED',
  CANCELLED: 'CANCELLED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
};

export const ORDER_STATUS_LABELS = {
  [ORDER_STATUS.NEW]: 'Ready to Schedule',
  [ORDER_STATUS.SCHEDULED]: 'Appointment Scheduled',
  [ORDER_STATUS.COLLECTED]: 'Sample Collected',
  [ORDER_STATUS.RECEIVED_BY_LAB]: 'Running',
  [ORDER_STATUS.REPORTED]: 'Ready',
  [ORDER_STATUS.CANCELLED]: 'Appointment Cancelled',
  [ORDER_STATUS.PENDING_APPROVAL]: 'Pending Approval',
};

export const ORDER_STATUS_LIST = Object.entries(ORDER_STATUS_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const ORDER_TYPES = {
  SELF_PAY: 'SELF_PAY',
  INSURANCE: 'INSURANCE',
};

export const ORDER_TYPES_LABELS = {
  [ORDER_TYPES.SELF_PAY]: 'Self-pay',
  [ORDER_TYPES.INSURANCE]: 'Insurance',
};

export const ORDER_TYPES_LIST = Object.entries(ORDER_TYPES_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const LOCATIONS_PROVIDER = {
  BURNSVILLE: {
    label: 'Burnsville',
    address: '2428 East 117th St, Burnsville, MN 55337',
  },
  MINNEAPOLIS: {
    label: 'Minneapolis',
    address: '2200 E Franklin Ave Suite 199, Minneapolis, MN 55404',
  },
  WOODBURY: {
    label: 'Woodbury',
    address: '1789 Woodlane Dr suite a, Woodbury, MN 55125',
  },
  ROCHESTER: {
    label: 'Rochester',
    address: '25 16th St NE, Rochester, MN 55906',
  },
};

export const LOCATIONS_PROVIDER_LIST = Object.entries(LOCATIONS_PROVIDER).map(
  ([value, label]) => ({
    value,
    label: label.address ? `${label.label} (${label.address})` : label.label,
  }),
);
