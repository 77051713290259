import {
  MdEvent,
  MdGroup,
  MdHourglassFull,
  MdReorder,
  MdSync,
  MdBusiness,
} from 'react-icons/md';

export default [
  {
    label: 'Appointments',
    to: '/appointments',
    Icon: MdEvent,
    action: 'list',
    subject: 'appointments',
  },
  {
    label: 'My Tests',
    to: '/my-tests',
    Icon: MdHourglassFull,
    action: 'list',
    subject: 'my-tests-menu',
  },
  {
    label: 'Organizations',
    to: '/organizations',
    Icon: MdBusiness,
    action: 'list',
    subject: 'organization',
    exact: false,
  },
  {
    label: 'Users',
    to: '/users',
    Icon: MdGroup,
    action: 'list',
    subject: 'users',
    exact: false,
  },
  {
    label: 'Patients',
    to: '/patients',
    Icon: MdGroup,
    action: 'list',
    subject: 'patients',
    exact: false,
  },
  {
    label: 'Lab Orders',
    to: '/lab-orders',
    Icon: MdReorder,
    action: 'list',
    subject: 'lab-orders',
    exact: false,
  },
  {
    label: 'E-Visits',
    to: '/e-visits',
    Icon: MdReorder,
    action: 'list',
    subject: 'e-visits',
    exact: false,
  },
  {
    label: 'Activity Logs',
    to: '/activity-logs',
    Icon: MdSync,
    action: 'list',
    subject: 'activity-logs',
  },
];
