import React, { createContext, useMemo } from 'react';
import { node } from 'prop-types';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { createContextualCan, useAbility } from '@casl/react';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';

const propTypes = {
  children: node.isRequired,
};

const AbilityContext = createContext();

export const usePermissions = () => useAbility(AbilityContext);

export const Can = createContextualCan(AbilityContext.Consumer);

const PermissionsProvider = ({ children }) => {
  const { user } = useAuth();

  const ability = useMemo(
    () =>
      new Ability(
        user && user.permissions ? unpackRules(user.permissions) : [],
      ),
    [user],
  );

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

PermissionsProvider.propTypes = propTypes;

export default PermissionsProvider;
