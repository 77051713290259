export const STATUS = new Map([
  [0, 'Inactive'],
  [false, 'Inactive'],
  [1, 'Active'],
  [true, 'Active'],
]);

export const STATUS_LIST = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
];

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  PATIENT: 'ROLE_PATIENT',
  COLLECTOR: 'ROLE_COLLECTOR',
  SCHEDULER: 'ROLE_SCHEDULER',
  LIMS: 'ROLE_LIMS',
  EXTERNAL_ADMIN: 'ROLE_EXTERNAL_ADMIN',
  PROVIDER: 'ROLE_PROVIDER',
};

export const ROLES_LABELS = {
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.PATIENT]: 'Patient',
  [ROLES.COLLECTOR]: 'Collector',
  [ROLES.SCHEDULER]: 'Scheduler',
  [ROLES.LIMS]: 'Lims',
  [ROLES.EXTERNAL_ADMIN]: 'External Administrator',
  [ROLES.PROVIDER]: 'Provider',
};

export const ALL_ORGANIZATIONS = {
  name: 'All Organizations',
  id: '',
};

export const ROLES_LIST = Object.entries(ROLES_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
