import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import logo from '../../assets/images/logo.png';
import logo2x from '../../assets/images/logo@2x.png';
import leftDoc from '../../assets/images/left-doc.png';
import rightDoc from '../../assets/images/right-doc.png';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90vh',
    px: 4,
    width: '100%',
  }),
);

const Wrapper = styled(Card)(
  css({
    mx: -6,
    position: 'relative',
    zIndex: 'docked',
  }),
);

const Logo = styled.img(
  css({
    display: 'block',
    mb: 2,
    mx: 'auto',
  }),
);

const DoctorImage = styled.img(
  css({
    display: ['none', null, 'block'],
    top: '200px',
    position: ['none', null, 'sticky'],
  }),
);

const LoggedOutWideLayout = ({ children }) => (
  <Main>
    <DoctorImage src={leftDoc} />
    <Wrapper width="760px">
      <Card.Body>
        <Logo
          src={logo}
          srcSet={`${logo} 1x, ${logo2x} 2x`}
          alt="Valley Medical Laboratory"
        />
        {children}
      </Card.Body>
    </Wrapper>
    <DoctorImage src={rightDoc} />
  </Main>
);

LoggedOutWideLayout.propTypes = propTypes;

export default LoggedOutWideLayout;
