/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
import React, { useState, useEffect, useContext, createContext } from 'react';
import { node } from 'prop-types';

export const DataContext = createContext();

export const usePixelAnalitycsContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error(
      'usePixelAnalitycsContext must be used within a DataContext',
    );
  }
  return context;
};

const propTypes = {
  children: node.isRequired,
};

const PixelAnalitycs = ({ children }) => {
  const [context, setContext] = useState({});
  const [mounted, setMount] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const w = window;
        const d = document;
        const s = d.createElement('script');
        s.setAttribute('async', 'true');
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('src', '//c1.rfihub.net/js/tc.min.js');
        const f = d.getElementsByTagName('script')[0];
        f.parentNode.insertBefore(s, f);
        s.onload = () => {
          if (typeof w._rfi !== 'function') {
            w._rfi = () => {
              w._rfi.commands = w._rfi.commands || [];
              //  w['_rfi'].commands.push(rest);
            };
          }
          w._rfi('setArgs', 'ver', '9');
          w._rfi('setArgs', 'rb', '45696');
          w._rfi('setArgs', 'ca', '20838225');
          w._rfi('setArgs', '_o', '45696');
          w._rfi('setArgs', '_t', '20838225');

          const track = () => {
            w._rfi('track');
          };
          setContext({
            _rfi: w._rfi,
            track,
          });
          setMount(true);
        };
      } catch (error) {
        throw Error(error);
      }
    })();
  }, []);

  return (
    <DataContext.Provider value={context}>
      {mounted ? children : null}
    </DataContext.Provider>
  );
};

PixelAnalitycs.propTypes = propTypes;

export default PixelAnalitycs;
